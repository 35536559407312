import React from 'react'
import {
  Badge as BSBadge,
  Card as BSCard,
  Row,
  Col as BSCol,
  Table,
  Button as BSButton
} from 'react-bootstrap'
// import Features from '../components/sections/features'
// import GetStarted from '../components/sections/getstarted'
import styled from 'styled-components'
import PageTemplate from '../templates/pageTemplate'

const Big = styled.span`
  font-size: 2rem;
`

const Muted = styled.span`
  color: ${(props) => props.theme.color.grey};
`

const Header = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
`

const Card = styled(BSCard)`
  // max-width: 800px;
`

const Badge = styled(BSBadge)`
  color: ${(props) => props.theme.color.accent};
  border: 1px solid ${(props) => props.theme.color.white.darker};
  padding: 5px;
  margin-left: 15px;
`

const Info = styled.div`
  background: ${(props) => props.theme.color.white.darker};
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  font-size: 0.9rem;
  margin-top: 20px;
`

const Col = styled(BSCol)`
  &.bg-accent {
    background: ${(props) => props.theme.color.accent};
  }
  min-height: 477px;

  z-index: 2;
  &:not(:first-child) {
    @media (min-width: ${(props) => props.theme.screen.md}) {
      border-left: 1px solid ${(props) => props.theme.color.white.darker};
    }
  }
`

const EnquireNowButton = styled(BSButton)`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color.black.regular};
  border-radius: 4px;
  display: block;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover,
  &:active,
  &:focus {
    background: ${(props) => props.theme.color.black.regular} !important;
    filter: brightness(150%) !important;
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px !important;
  }
`

const specsToTable = (str) =>
  str
    .trim()
    .split(/\r?\n/)
    .map((line, idx) => {
      const [th, td] = line.trim().split(/:\s/)
      return (
        <tr key={idx}>
          <th>{th}</th>
          <td>{td}</td>
        </tr>
      )
    })

// 250 GB/month included.

const IndexPage = () => (
  <PageTemplate title="Pricing">
    <h2>Pricing</h2>
    <div className="my-4">
      Virtual machines with a healthy balance of memory and dedicated compute.
    </div>

    <Card className="shadow mx-auto mt-5">
      <Card.Body className="py-0 px-3">
        <Row>
          <Col lg="4" className="bg-white py-4 px-5">
            <div>
              <Header>Standard</Header>
            </div>
            <div className="my-3">
              <Big>$249</Big>
              <Muted>/month ex GST</Muted>
            </div>

            <Table className="mb-0 mt-3">
              {specsToTable(`
              OS: macOS Catalina 10.15.6
              vCPUs: 2
              Memory: 8 GB
              SSD Disk: 200 GB
              Bandwidth: 250 GB/month
            `)}
            </Table>
            <Info>Additional data $30 per extra 250GB</Info>
          </Col>

          <Col lg="4" className="bg-white py-4 px-5">
            <div>
              <Header>Pro</Header>
              <Badge>Most popular</Badge>
            </div>
            <div className="my-3">
              <Big>$399</Big>
              <Muted>/month ex GST</Muted>
            </div>

            <Table className="mb-0 mt-3">
              {specsToTable(`
    OS: macOS Catalina 10.15.6
    vCPUs: 4
    Memory: 16 GB
    SSD Disk: 400 GB
    Bandwidth: 250 GB/month
  `)}
            </Table>
            <Info>Additional data $30 per extra 250GB</Info>
          </Col>

          <Col lg="4" className="bg-accent py-4 px-5 d-flex flex-column">
            <div>
              <Header>Custom</Header>
            </div>
            <div className="my-3">
              <Big>Request a Quote</Big>
            </div>
            <div>Talk to us about you needs</div>
            <EnquireNowButton
              className="my-3 w-100 d-flex justify-content-center align-items-center"
              href="mailto:info@mountain-pass.com.au"
            >
              Enquire Now
            </EnquireNowButton>
            <div className="flex-grow-1" />
            <Info>Fixed and volume pricing available</Info>
          </Col>
        </Row>
        <div className="dots d-none d-sm-block" />
      </Card.Body>
    </Card>
  </PageTemplate>
)

export default IndexPage
